import React from 'react';
import { Box, Container, Flex, Heading, Text, Button, chakra } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const MotionBox = chakra(motion.div);
const MotionImage = chakra(motion.img);

export const Intro: React.FC = () => {
  const transition = {
    duration: 0.3,
    ease: [0.4, 0, 0.2, 1],
  };

  return (
    <Box className="features-lg" bg="gray.50" py={10}>
      <Container maxW="container.xl">
        <Flex align="center" justify="space-between" flexDirection={{ base: 'column', lg: 'row' }}>
          <MotionBox
            className="section-stack order-lg-2 mb-4 mb-lg-0 position-relative"
            data-aos="fade-up"
            data-aos-delay="0"
            flex="1"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={transition as any}
            position="relative"
          >
            <Flex className="image-stack" position="relative">
              <MotionImage
                src={require("../../assets/images/voipIntro.jpg")}
                alt="Bottom Image"
                borderRadius="lg"
                initial={{ scale: 0.95 }}
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 } as any}
              />
            </Flex>
          </MotionBox>

          <MotionBox
            className="section-title"
            flex="1"
            data-aos="fade-up"
            data-aos-delay="100"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={transition as any}
          >
            <Heading as="h2" fontWeight="bold" mb={4} className="heading blutext animate__animated animate__fadeIn animate__infinite my-fade-in">
              Welcome to Linkorg VoIP
            </Heading>
            <Text mb={4} fontSize="lg" color="gray.700">
              In today's fast-paced world, dependable, flexible 
              communication is essential.<br/><br/> At LinkOrgVoip, we specialise 
              in delivering cutting-edge Voice over Internet Protocol (VoIP) 
              services tailored to meet the needs of businesses of all sizes. 
              Whether you're a solo entrepreneur, managing a corporate call 
              centre or running a fleet of offshore vessels, we've got 
              you covered!<br/><br/> Our advanced VoIP solutions leverage a unique 
              combination of LEO and GEO satellite technologies, fibre optics, 
              and robust IP-PBX systems to ensure crystal-clear voice 
              communication wherever you are, even in the most challenging 
              environments. With our expert team and tailored solutions, let 
              us enhance your business with seamless connectivity.<br/><br/>
              Discover how LinkOrgVoip can transform your communication today!
            </Text>
            <Button as="a" href="/about" colorScheme="orange" variant="solid" size="lg">
              Get to know us
            </Button>
          </MotionBox>
        </Flex>
      </Container>
    </Box>
  );
};
