import React from 'react';
import { Box, Container, Flex, Heading, Text, chakra } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const MotionBox = chakra(motion.div);
const MotionImage = chakra(motion.img);

export const VoipRequirements: React.FC = () => {
  const transition = {
    duration: 0.3,
    ease: [0.4, 0, 0.2, 1],
  };

  return (
    <Box className="features-lg" bg="white" py={10}>
      <Container maxW="container.xl">
        <Flex align="center" justify="space-between" flexDirection={{ base: 'column', lg: 'row' }}>
          <MotionBox
            className="section-stack mb-4 mb-lg-0"
            data-aos="fade-up"
            data-aos-delay="0"
            flex="1"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={transition as any}
          >
            <MotionImage
              src={require("../../assets/images/voipReq.jpg")}
              alt="VoIP Requirements"
              className="img-fluid"
              borderRadius="lg"
              initial={{ scale: 0.95 }}
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 } as any}
            />
          </MotionBox>

          <MotionBox
            className="section-title"
            flex="1"
            data-aos="fade-up"
            data-aos-delay="100"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={transition as any}
          >
            <Heading as="h2" fontWeight="bold" className="blutext animate__animated animate__bounce animate__infinite" mb={4}>
              VoIP Requirements
            </Heading>
            <Text mb={4} fontSize="lg" color="gray.700">
              VoIP Account from Linkorg Voip. Available internet access (any reliable internet with no active block on VoIP is adequate). Phone – hard phone and/or soft phone app, which can be installed on Windows laptop/desktop, Android devices, or iPhones.
            </Text>
          </MotionBox>
        </Flex>
      </Container>
    </Box>
  );
};
