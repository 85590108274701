import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  GridItem,
  Text,
  List,
  ListItem,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

type Plan = {
  id: number;
  title: string;
  monthlyPrice: number;
  features: string[];
};

type PlanCardProps = {
  plan: Plan;
  isHovered: boolean;
  setHovered: (id: number | null) => void;
};

const packagesData = [
  {
    id: 1,
    title: 'Silver',
    monthlyPrice: 5.99,
    features: [
      'Inbound calls only',
      'UK number included',
      'Mobile OR desktop apps (iOS® and Android™)',
      'Call logs',
    ],
  },
  {
    id: 2,
    title: 'Gold',
    monthlyPrice: 14.99,
    features: [
      'Everything in Silver plan PLUS',
      '1000 minutes of free call (UK landline and mobile)',
      'Call monitoring and call logs',
      'On-Demand Call Recording',
      'Voicemail to email',
      'Unlimited audio conferencing',
      'VR, ring group, call queue and more. Click to dial (additional cost)',
      'Click to dial from most popular CRMs. Automatic call recording (additional cost)',
      'Manage your whole team on different levels',
      'Quality-of-service reports',
    ],
  },
  {
    id: 3,
    title: 'Diamond',
    monthlyPrice: 19.99,
    features: [
      'Everything in Gold Plan PLUS',
      '2000 minutes of free calls (UK landline and mobile)',
      'Automatic Call Recording',
      'Multi-site admin and management',
      'Advanced call handling, including whisper, barge and more',
      'Empower your managers with powerful analytics Collaboration tools',
      'Video conferencing, screen share, chat and SMS Developer platform',
      'Full API access',
      '100+ CRM Integrations and API access, including Salesforce, Zoho, HubSpot and Bullhorn (additional cost)',
    ],
  },
];

const PlanCard: React.FC<PlanCardProps> = ({ plan, isHovered, setHovered }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box
        onMouseEnter={() => setHovered(plan.id)}
        onMouseLeave={() => setHovered(null)}
        p={5}
        borderRadius="md"
        boxShadow="md"
        transition="transform 0.3s, background-color 0.3s"
        transform={isHovered ? 'scale(1.05)' : 'scale(1)'}
        bg={isHovered ? '#010156' : '#F3F6FB'}
        _hover={{
          backgroundColor: '#010156',
          color: 'white',
        }}
        color={isHovered ? 'white' : '#2A4365'}
        maxW="350px"
        mx="auto"
      >
        <Text fontSize="2xl" fontWeight="bold" mb={4}>
          {plan.title}
        </Text>
        <Box mb={3}>
          <Text fontSize="xl" fontWeight="bold">&#163;{plan.monthlyPrice}</Text>
          <Text fontSize="sm"> /month</Text>
        </Box>
        <Box overflowY="auto" maxH="250px" px={2} w="full">
          <List spacing={3} color={isHovered ? 'white' : 'gray.700'}>
            {plan.features.map((feature: string, idx: number) => (
              <ListItem key={idx} display="flex" alignItems="start">
                <CheckIcon color={isHovered ? 'white' : 'blue.500'} mr={2} />
                <Text fontSize="sm">{feature}</Text>
              </ListItem>
            ))}
          </List>
        </Box>
        <Box textAlign="center" mt={4}>
          <Button colorScheme="orange" size="lg" onClick={onOpen}>
            Get Started
          </Button>
        </Box>
      </Box>

      {/* Modal for Form */}
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className="blutext">Sign Up for {plan.title} Plan</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <iframe 
            width="640px" 
            height="480px" 
            src="https://forms.office.com/Pages/ResponsePage.aspx?id=Qe231rL0Hk2eCUTnsY0M-pAgxe5bp1NGtlFi8KQ2mrlUMldKVzdKWElRVjg1U0JWOUpKQUcyR1NLOS4u&embed=true" 
            frameBorder={0} 
            marginWidth={0} 
            marginHeight={0} 
            style={{ border: 'none', maxWidth: '100%', maxHeight: '100vh' }} 
            allowFullScreen
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
          />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export const Packages = () => {
  const [hovered, setHovered] = useState<number | null>(null);

  return (
    <Box p={8} maxW="1200px" mx="auto">
      <Text fontSize="4xl" fontWeight="bold" textAlign="center" mb={4} color="#010156">
        Packages
      </Text>
      <Text textAlign="center" color="gray.700" fontSize="2xl" mb={8}>
        UK Business VoIP Virtual Landline Provider
      </Text>
      <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }} gap={6}>
        {packagesData.map((plan) => (
          <GridItem key={plan.id} display="flex" justifyContent="center">
            <PlanCard plan={plan} isHovered={hovered === plan.id} setHovered={setHovered} />
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
};
