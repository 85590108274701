import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { useStripe, useElements, PaymentElement, Elements } from '@stripe/react-stripe-js';
import { Box, Button, Text } from '@chakra-ui/react';
import { loadStripe } from '@stripe/stripe-js';

interface PaymentProps {
  amount: number | null;
  userInfo: { email?: string };
}

const stripePromise = loadStripe("pk_live_51QIQGdP8MdHf1E0aoaEz6vArMtkofrdwKbpF66LedwC7g1JW7M8q0awmMGEAluKp3mr7IBWYnMyuvKHSRJGW2P3000tJSAaUYj");

export const Payment: React.FC<PaymentProps> = ({ amount, userInfo }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState<string>(userInfo?.email || '');
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [clientSecret, setClientSecret] = useState<string | null>(null);

  useEffect(() => {
    if (!userInfo?.email) {
      const storedEmail = localStorage.getItem('userEmail');
      if (storedEmail) {
        setEmail(storedEmail);
      }
    }
  }, [userInfo]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!email || !email.includes("@")) {
      setErrorMessage("Invalid email.");
      return;
    }

    if (!amount || amount <= 0) {
      setErrorMessage("Invalid amount.");
      return;
    }

    setLoading(true);
    setErrorMessage("");

    try {
      const response = await fetch("https://linkorg-voip.vercel.app/api/v1/payment/stripe-payments", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          amount,
          email,
          automatic_payment_methods: { enabled: true },
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        console.error("Payment API error:", data);
        setErrorMessage(data.message || "Failed to process payment.");
        return;
      }

      if (data.paymentIntent?.client_secret) {
        setClientSecret(data.paymentIntent.client_secret);
      } else {
        setErrorMessage("Failed to fetch payment details.");
      }
    } catch (err) {
      setErrorMessage("An error occurred while fetching payment details.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (newEmail && newEmail !== userInfo?.email) {
      localStorage.setItem('userEmail', newEmail);
    }
  };

  const confirmPayment = async () => {
    if (!stripe || !elements || !clientSecret) {
      setErrorMessage('Stripe.js or Elements is not loaded yet or clientSecret is missing.');
      return;
    }

    setLoading(true);
    try {
      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: `${window.location.origin}/success`,
        }
      });

      if (error) {
        setErrorMessage(error.message || 'An unknown error occurred');
      } else {
        setSuccessMessage('Payment successful!');
        navigate('/success');
      }
    } catch (err) {
      setErrorMessage('Payment failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box maxW="600px" mx="auto" p={4}>
      <Text fontSize="3xl" fontWeight="bold" textAlign="center" className="blutext" mb={6}>Payment Page</Text>
      {successMessage && <Text color="green.500">{successMessage}</Text>}
      {clientSecret ? (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <form onSubmit={(e) => { e.preventDefault(); confirmPayment(); }}>
            <Box mb={3}>
              <label htmlFor="email-input"><b>Email</b></label>
              <input 
                value={email} 
                type="email" 
                id="email-input" 
                placeholder="Enter your email address" 
                onChange={handleEmailChange} 
              />
            </Box>
            <Box mb={3}>
              <label htmlFor="price"><b>Amount to be Paid</b></label>
              <Text fontSize="xl" fontWeight="bold">£{amount?.toFixed(2)}</Text>
            </Box>
            <PaymentElement />
            <Box mt={4} textAlign="center">
              <Button type="submit" colorScheme="orange" isLoading={loading} disabled={loading}>Complete Payment</Button>
            </Box>
          </form>
        </Elements>
      ) : (
        <form onSubmit={handleSubmit}>
          <Box mb={3}>
            <label htmlFor="email-input"><b>Email</b></label>
            <input 
              value={email} 
              type="email" 
              id="email-input" 
              placeholder="Enter your email address" 
              onChange={handleEmailChange} 
              className="ms-3"
            />
          </Box>
          <Box mb={3}>
            <label htmlFor="price"><b className="blutext">Amount to be Paid</b></label>
            <Text className="ms-3" fontSize="xl" fontWeight="semibold">£{amount?.toFixed(2)}</Text>
          </Box>
          <Box mt={4} textAlign="center">
            <Button type="submit" colorScheme="orange" isLoading={loading} disabled={loading}>Get Payment Details</Button>
          </Box>
        </form>
      )}
    </Box>
  );
};