import { Box } from "@chakra-ui/react";
import React from "react";
import { Wishlist } from "../components/Cart/Wishlist";
import { WishlistProvider } from "../WishlistContext";



const WishListPage: React.FC = () => {
    return(
        <Box>
            <WishlistProvider>
                <Wishlist />
            </WishlistProvider>

        </Box>
    )
}



export default WishListPage;