import React from "react";
import { Box } from "@chakra-ui/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js"; // 
import {Payment} from "../components/Payment";

// Define the types for the props
interface PayProps {
  amount: number | null;
  userInfo: {
    email: string;
  };
}

// Load Stripe with your public key
const stripePromise = loadStripe("pk_live_51QIQGdP8MdHf1E0aoaEz6vArMtkofrdwKbpF66LedwC7g1JW7M8q0awmMGEAluKp3mr7IBWYnMyuvKHSRJGW2P3000tJSAaUYj");

const Pay: React.FC<PayProps> = ({ amount, userInfo }) => {
  return (
    <Box>
      <Elements stripe={stripePromise}>
        <Payment amount={amount} userInfo={userInfo} />
      </Elements>
    </Box>
  );
};

export default Pay;
