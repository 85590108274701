import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { UserContext } from "./usercontext";

interface WishlistItem {
  productId: string;
  name: string;
  price: number;
  imageUrl: string;
}

interface WishlistContextType {
  wishlist: WishlistItem[];
  addToWishlist: (productId: string, itemData: WishlistItem) => void;
  removeFromWishlist: (productId: string) => void;
}

export const WishlistContext = createContext<WishlistContextType | undefined>(undefined);

export const WishlistProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useContext(UserContext);
  const [wishlist, setWishlist] = useState<WishlistItem[]>([]);

  useEffect(() => {
    if (user) {
      syncGuestWishlist();
      fetchWishlist();
    } else {
      loadGuestWishlist();
    }
  }, [user]);

  // Fetch wishlist for logged-in users
  const fetchWishlist = async () => {
    try {
      const response = await axios.get(`https://linkorg-voip.vercel.app/api/v1/wishlist/${user?.id}`);
      setWishlist(response.data.wishlist);
    } catch (error) {
      console.error("Error fetching wishlist:", error);
    }
  };

  // Load wishlist from localStorage for guests
  const loadGuestWishlist = () => {
    const storedWishlist = localStorage.getItem("guestWishlist");
    if (storedWishlist) {
      setWishlist(JSON.parse(storedWishlist));
    }
  };

  // Sync guest wishlist to server upon login
  const syncGuestWishlist = async () => {
    const storedWishlist = localStorage.getItem("guestWishlist");
    if (storedWishlist) {
      const guestWishlist = JSON.parse(storedWishlist);
      try {
        await axios.post("https://linkorg-voip.vercel.app/api/v1/wishlist/sync", {
          userId: user?.id,
          wishlist: guestWishlist,
        });
        localStorage.removeItem("guestWishlist"); // Clear guest wishlist after sync
      } catch (error) {
        console.error("Error syncing guest wishlist:", error);
      }
    }
  };

  // Add item to wishlist
  const addToWishlist = async (productId: string, itemData: WishlistItem) => {
    if (user) {
      try {
        await axios.post("https://linkorg-voip.vercel.app/api/v1/wishlist", {
          userId: user.id,
          productId,
        });
        fetchWishlist();
      } catch (error) {
        console.error("Error adding to wishlist:", error);
      }
    } else {
      const updatedWishlist = [...wishlist, itemData];
      setWishlist(updatedWishlist);
      localStorage.setItem("guestWishlist", JSON.stringify(updatedWishlist));
    }
  };

  // Remove item from wishlist
  const removeFromWishlist = async (productId: string) => {
    if (user) {
      try {
        await axios.delete(`https://linkorg-voip.vercel.app/api/v1/wishlist/${user.id}/${productId}`);
        fetchWishlist();
      } catch (error) {
        console.error("Error removing from wishlist:", error);
      }
    } else {
      const updatedWishlist = wishlist.filter((item) => item.productId !== productId);
      setWishlist(updatedWishlist);
      localStorage.setItem("guestWishlist", JSON.stringify(updatedWishlist));
    }
  };

  return (
    <WishlistContext.Provider value={{ wishlist, addToWishlist, removeFromWishlist }}>
      {children}
    </WishlistContext.Provider>
  );
};

export const useWishlist = () => {
  const { user } = useContext(UserContext); // Get user context to check if the user is logged in
  const [wishlist, setWishlist] = useState<any[]>([]);

  useEffect(() => {
    if (user) {
      // Fetch wishlist from the API when the user is logged in
      fetchWishlistFromAPI();
    } else {
      // Fetch wishlist from local storage when the user is not logged in
      fetchWishlistFromLocalStorage();
    }
  }, [user]); // Re-run when user changes (e.g., login or logout)

  // Fetch wishlist from the API
  const fetchWishlistFromAPI = async () => {
    try {
      const response = await fetch('/api/wishlist', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${user.token}`, // Assuming you're using JWT for authentication
        },
      });
      const data = await response.json();
      if (data && data.wishlist) {
        setWishlist(data.wishlist); // Assuming the API returns a wishlist object
      }
    } catch (error) {
      console.error('Error fetching wishlist from API:', error);
    }
  };

  // Fetch wishlist from local storage
  const fetchWishlistFromLocalStorage = () => {
    const savedWishlist = JSON.parse(localStorage.getItem('wishlist') || '[]');
    setWishlist(savedWishlist);
  };

  // Add item to wishlist
  const addToWishlist = (item: any) => {
    const updatedWishlist = [...wishlist, item];
    setWishlist(updatedWishlist);

    if (user) {
      // Save to the API if user is logged in
      saveToAPI(updatedWishlist);
    } else {
      // Save to local storage if the user is not logged in
      localStorage.setItem('wishlist', JSON.stringify(updatedWishlist));
    }
  };

  // Save updated wishlist to the API
  const saveToAPI = async (updatedWishlist: any) => {
    try {
      await fetch('/api/wishlist', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${user.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ wishlist: updatedWishlist }),
      });
    } catch (error) {
      console.error('Error saving wishlist to API:', error);
    }
  };

  // Remove item from wishlist
  const removeFromWishlist = (itemId: string) => {
    const updatedWishlist = wishlist.filter(item => item.id !== itemId);
    setWishlist(updatedWishlist);

    if (user) {
      // Remove from the API if user is logged in
      removeFromAPI(itemId);
    } else {
      // Remove from local storage if the user is not logged in
      localStorage.setItem('wishlist', JSON.stringify(updatedWishlist));
    }
  };

  // Remove from the API
  const removeFromAPI = async (itemId: string) => {
    try {
      await fetch(`/api/wishlist/${itemId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${user.token}`,
        },
      });
    } catch (error) {
      console.error('Error removing item from wishlist API:', error);
    }
  };

  return {
    wishlist,
    addToWishlist,
    removeFromWishlist,
  };
};
